import { useNavigation } from 'react-router'
import { useEffect } from 'react'
import NProgress from 'nprogress'

NProgress.configure({
  showSpinner: false, // disable the loading spinner
  minimum: 0.35, // initial progress (default: 0.08)
  speed: 400, // transition speed in ms (default: 200)
  // typing seems to be incorrect:
  // https://www.npmjs.com/package/nprogress
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  trickleRate: 0.1, // how much to increase per trickle (default: 0.02)
  trickleSpeed: 400, // how often to trickle, in ms (default: 200)
})

export const PageLoadingProgress = (): React.ReactNode => {
  const navigation = useNavigation()

  const state = navigation.state

  useEffect(() => {
    if (state === 'loading') NProgress.start()
    if (state === 'idle') NProgress.done()
  }, [state])

  return <></>
}
