import { AuthContext } from '~/auth/AuthContext'
import { AuthState, initialAuthState } from '~/auth/AuthState'

export interface AuthProviderBaseProps {
  /**
   * The child nodes your Provider has wrapped
   */
  children?: React.ReactNode

  /**
   * The initial state of the provider
   */
  initialState: AuthState
}

export const AuthProvider = ({
  children,
  initialState = initialAuthState,
}: AuthProviderBaseProps) => {
  return (
    <AuthContext.Provider value={initialState}>{children}</AuthContext.Provider>
  )
}
