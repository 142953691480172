import { UserinfoResponse } from 'openid-client'
import { UserType } from '~/api/__generated-types__'

export interface OidcUserInfo extends UserinfoResponse {
  company_id: string
  company_name: string
  company_vat: string
  company_address_street_code: string
  company_address_city: string
  roles: string[]
  is_admin: boolean
}

export interface AuthState {
  accessToken?: string
  refreshToken?: string
  userInfo?: UserType
  isAuthenticated: boolean
}

export const initialAuthState: AuthState = {
  isAuthenticated: false,
}
