import * as React from 'react'
// A nonce (short for "number used once") is a unique, random value generated for each request.
// In the context of Content Security Policy (CSP), a nonce is used to allow specific inline scripts to execute, preventing Cross-Site Scripting (XSS) attacks.
// This how it works:
// 1. The server generates a unique nonce value for each request.
// 2. The server includes the nonce in the Content-Security-Policy header.
// 3. The server includes the nonce in the HTML response as a nonce attribute on the script tag.
// 4. The browser checks the nonce in the script tag against the nonce in the Content-Security-Policy header.
// 5. If the nonce match, the browser allows the script to execute.
export const NonceContext = React.createContext<string>('')
export const NonceProvider = NonceContext.Provider
export const useNonce = () => React.useContext(NonceContext)

export function generateNonce(): string {
  return btoa(
    String.fromCharCode(...crypto.getRandomValues(new Uint8Array(32))),
  )
}
